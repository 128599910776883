
// @ts-nocheck
import LayoutFooter from '~/components/layout/LayoutFooter.vue'
import LayoutHeader from '~/components/layout/LayoutHeader.vue'
import MainPageContent from '~/components/new/main-page/MainPageContent.vue'
import PaintingsBlock from '~/components/new/main-page/PaintingsBlock.vue'
import { useModalStore } from '~/store/modals'
const modalStore = useModalStore()

export default {
  components: { LayoutHeader, LayoutFooter, PaintingsBlock, MainPageContent },
  layout: 'empty',
  async asyncData (context: Context) {
    const compilitationsRes = await context.$axios.get(process.env.API_ENDPOINT + '/api/compilations')
    if (compilitationsRes.status !== 200) {
      return
    }
    const compilations = compilitationsRes.data.data as Array<any>

    const newPaintingsRes = await context.$axios.get(process.env.API_ENDPOINT + '/api/artworks?page_size=4')
    if (newPaintingsRes.status !== 200) {
      return
    }
    const newPaintings = newPaintingsRes.data.data.map(
      (item) => {
        return { id: item.id, webpack_file: item.images?.[0]?.preview_url }
      }
    ) as Array<any>

    const newsRes = await context.$axios.get(process.env.API_ENDPOINT + '/api/posts')
    const news = newsRes?.data?.data?.filter(news => news.description || news.text || news.title).slice(0, 5)

    const newArtistsRes = await context.$axios.get(process.env.API_ENDPOINT + '/api/artists')
    const newArtists = newArtistsRes?.data?.data?.slice(0, 10)
    return {
      compilations,
      newPaintings,
      news,
      newArtists
    }
  },
  data () {
    return {
      displayGallery: !this.$route.query.page
    }
  },
  head () {
    return {
      title: this.$t('head.title.index'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('head.description.index') },
        { hid: 'og:title', name: 'og:title', content: this.$t('head.title.index') },
        { hid: 'og:description', name: 'og:description', content: this.$t('head.description.index') },
        { hid: 'og:url', name: 'og:url', content: process.env.API_ENDPOINT + this.$route.fullPath }
      ]
    }
  },
  watch: {
    displayGallery (value, old) {
      if (value === old) { return }

      this.$router.replace({ query: { page: value ? undefined : 'true' } })
    }
  },
  async mounted () {
    const paintingsEl: HTMLElement | undefined = this.$refs.paintings?.$vnode.elm
    const mainPageEl: HTMLElement = this.$refs.mainPage as HTMLElement
    if (!paintingsEl || !mainPageEl) { return }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) { modalStore.addModal() } else { modalStore.removeModal() }
      })
    }, { threshold: 1 })

    observer.observe(paintingsEl)

    await this.$nextTick()

    window.scroll({ top: 0 })
  },
  unMounted () {
    // @ts-ignore

  },

  methods: {
    safariScrollTop () {
      window.scrollTo({ top: 0 })
    },
    toGalleryLinkHandler () {
      // @ts-ignore
      this.displayGallery = true

      // @ts-ignore
    },
    async toPageLinkHandler () {
      // @ts-ignore
      this.displayGallery = false

      await this.$nextTick();

      (this.$refs.page as HTMLElement).scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}

// Мета теги
// useHead({
//   title: 'ArtHall - онлайн-галерея современного искусства. Ведущие художники. Интерьерные картины, подарок на любой случай',
//   meta: [
//     {
//       name: 'description',
//       content: 'ArtHall - онлайн-галерея современного российского искусства, где можно приобрести работы профессиональных и начинающих художников, тщательно подобранные экспертами. Каждый желающий сможет найти картину под свои цели: будь то дизайн или уникальный подарок на любой праздник.'
//     }
//   ]
// })
